import Cabinet from "@/pages/Cabinet";
import Dashboard from "@/components/cabinet/Dashboard";
import EmailVerify from "@/pages/EmailVerify";
import Fail from "@/components/pay/Fail";
import Login from "@/pages/Login";
import LoginCheck from "@/pages/LoginCheck";
import ReferralInfo from "@/components/cabinet/ReferraInfo";
import Register from "@/pages/Register";
import ResetPassword from "@/pages/ResetPassword";
import Settings from "@/components/cabinet/Settings";
import Staking from "@/components/cabinet/Staking";
import Status from "@/components/pay/Status";
import Success from "@/components/pay/Success";
import Trans from "@/localization/translation";
import Transactions from "@/components/cabinet/Transactions";
import Tutorial from "@/components/cabinet/Tutorial";
import Vesting from "@/components/cabinet/Vesting";
import Vip from "@/pages/Vip";

import auth from "@/auth";

import { createRouter, createWebHistory, RouterView } from "vue-router";


const routes = [{
  path: "",
  beforeEnter: Trans.routeMiddleware,
  component: RouterView,
  redirect: () => {
    return { path: "/:locale?" };
  },
  children: [
    {
      path: "/:locale?",
      name: "Main",
      meta: {
        isMain: true
      }
    },
    {
      path: "/login/:locale?",
      name: "Login",
      component: Login,
      meta: {
        isMain: false
      }
    },
    {
      path: "/register/:locale?",
      name: "Register",
      component: Register,
      meta: {
        isMain: false
      }
    }
  ]
},
{
  name: "cabinet",
  component: Cabinet,
  meta: {
    isMain: false
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        menuId: 0
      }
    },
    {
      path: "/transactions",
      name: "Transactions",
      component: Transactions,
      meta: {
        menuId: 2
      }
    },
    {
      path: "/referral-info",
      name: "referral info",
      component: ReferralInfo,
      meta: {
        menuId: 3
      }
    },
    {
      path: "/staking",
      name: "staking",
      component: Staking,
      meta: {
        menuId: 4
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        menuId: 8
      }
    },
    {
      path: "/tutorial",
      name: "tutorial",
      component: Tutorial,
      meta: {
        menuId: 6
      }
    },
    {
      path: "/vesting",
      name: "vesting",
      component: Vesting,
      meta: {
        menuId: 5
      }
    }
  ]
},
{
  path: "/email_verify/:hash",
  name: "EmailVerify",
  props: true,
  component: EmailVerify
},
{
  path: "/reset_password/:code?",
  name: "reset password",
  props: true,
  component: ResetPassword
},
{
  path: "/login_check",
  name: "login check",
  component: LoginCheck
},
{
  path: "/pay/adv/success",
  name: "Pay Success",
  component: Success
},
{
  path: "/pay/adv/fail",
  name: "Pay Fail",
  component: Fail
},
{
  path: "/pay/adv/status",
  name: "Pay Status",
  component: Status
},
{
  path: "/vip/:locale?",
  name: "Vip",
  component: Vip
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, _from, next) => {
  let authMem = null;
  try {
    authMem = JSON.parse(auth.remember());
  }
  catch (e) {
    authMem = null;
  }
  if (authMem ||
    to.name === "Login" || to.name === "Main" ||
    to.name === "reset password" || to.name === "Register") {
    next();
  }
  else {
    next({ name: "Login", params: { locale: Trans.currentLocale } });
  }
});

export default router;
